import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: 'User',
  mixins: [mixins],
  data() {
    return {
      changePwdDialog: false,
      changePwdForm: {
        userId: null,
        newPwd: '',
        newPwd1: ''
      },
      changePwdFormRules: {
        newPwd: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }, {
          min: 8,
          max: 11,
          message: '长度在 8 到 11 个字符',
          trigger: 'blur'
        }],
        newPwd1: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }, {
          min: 8,
          max: 11,
          message: '长度在 8 到 11 个字符',
          trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请输入确认密码'));
            } else if (value !== this.changePwdForm.newPwd) {
              callback(new Error('两次密码不匹配'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }]
      },
      // 表格定义
      columns: [{
        label: '用户名',
        customRender: props => {
          return _createVNode("span", null, [props.row.userCode, _createTextVNode(" ("), props.row.userName, _createTextVNode(")")]);
        }
      }, {
        prop: 'phone',
        label: '联系电话'
      }, {
        prop: 'loginType',
        label: '登录方式'
      }, {
        label: '使用状态',
        customRender: props => {
          if (this.$hasPermission('user_enable')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.enabled,
              "onUpdate:modelValue": $event => props.row.enabled = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeEnabled(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.enabled ? '使用中' : '已禁用';
          }
        }
      }, {
        label: '锁定状态',
        customRender: props => {
          if (this.$hasPermission('user_lock')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.locked,
              "onUpdate:modelValue": $event => props.row.locked = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.lock(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.locked ? '已锁定' : '未锁定';
          }
        }
      }, {
        label: '有效期',
        customRender: props => {
          var _props$row$validateDa, _props$row$invalidate;
          return _createVNode("span", null, [(_props$row$validateDa = props.row.validateDate) !== null && _props$row$validateDa !== void 0 ? _props$row$validateDa : '-', _createTextVNode(" \u81F3 "), (_props$row$invalidate = props.row.invalidateDate) !== null && _props$row$invalidate !== void 0 ? _props$row$invalidate : '长期']);
        }
      }, {
        prop: 'lastLoginTime',
        label: '最后登录时间'
      }],
      // 顶部按钮
      topButtons: [{
        name: '新增',
        code: 'add',
        type: 'primary',
        click: this.add,
        permissions: ['user_add']
      } /*, {
           name: (selectRows) => {
               if (selectRows && selectRows.length > 1) {
                   return '批量删除';
               } else {
                   return '删除';
               }
           },
           disabled: (selectRows) => {
               return !selectRows || selectRows.length === 0;
           },
           code: 'deleteUser',
           type: 'danger',
           click: this.deleteUser,
           permissions: ['user_del']
        }*/],

      // 行按钮
      linkButtons: [{
        name: '修改',
        code: 'editUser',
        click: this.editUser,
        permissions: ['user_edit']
      }, {
        name: '绑定证书',
        code: 'bindCert',
        click: this.bindCert,
        permissions: ['user_bind_cert']
      }, {
        name: '重置密码',
        code: 'resetPwd',
        click: this.resetPwd,
        permissions: ['user_reset_pwd']
      }, {
        name: '查看详情',
        code: 'userDetail',
        click: this.userDetail,
        permissions: ['user_detail']
      }],
      // 搜索字段
      searchItems: [{
        prop: 'userCode',
        label: '用户编号'
      }, {
        prop: 'userName',
        label: '用户名称'
      }, {
        type: 'cascader',
        label: '所属部门',
        prop: 'deptId',
        props: {
          checkStrictly: true,
          emitPath: false,
          value: 'deptId',
          label: 'deptName',
          lazy: true,
          lazyLoad: (node, resolve) => {
            const {
              level
            } = node;
            if (level === 0) {
              this.$api.uac.dept.list().then(res => {
                if (res.code === 200) {
                  resolve(res.data);
                } else {
                  resolve([]);
                }
              });
            } else {
              resolve([]);
            }
          }
        }
      }],
      switchLoading: false
    };
  },
  methods: {
    changeEnabled(user, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          return resolve(true);
        } else {
          ElMessage.error(res.message);
          return reject(new Error('Error'));
        }
      };
      if (user.enabled) {
        this.$api.uac.user.disable(user.userId).then(callBack).finally(() => this.switchLoading = false);
      } else {
        this.$api.uac.user.enable(user.userId).then(callBack).finally(() => this.switchLoading = false);
      }
    },
    lock(user, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          if (resolve) {
            return resolve(true);
          }
        } else {
          ElMessage.error(res.message);
          if (reject) {
            return reject(new Error('Error'));
          }
        }
      };
      if (user.locked) {
        this.$api.uac.user.unLock(user.userId).then(callBack).finally(() => this.switchLoading = false);
      } else {
        this.$api.uac.user.lock(user.userId).then(callBack).finally(() => this.switchLoading = false);
      }
    },
    add() {
      this.$router.push('/userAdd');
    },
    deleteUser(selectRows) {
      console.log('deleteUser', selectRows);
    },
    editUser(row) {
      this.$router.push({
        path: '/userAdd',
        query: {
          userId: row.userId
        }
      });
    },
    bindCert() {},
    resetPwd(row) {
      this.changePwdForm = {
        userId: row.userId,
        newPwd: '',
        newPwd1: ''
      };
      this.changePwdDialog = true;
    },
    doChangePwd() {
      this.$refs.pwdForm.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          this.$api.uac.user.forceChangePwd(this.changePwdForm.userId, this.changePwdForm.newPwd).then(res => {
            if (res.code === 200) {
              this.changePwdDialog = false;
              ElMessage({
                message: res.message,
                type: 'success'
              });
            } else {
              ElMessage({
                message: res.message,
                type: 'warning'
              });
            }
          }).finally(() => {
            loading.close();
          });
        }
      });
    },
    userDetail(row) {
      this.$router.push({
        path: '/userDetail',
        query: {
          userId: row.userId
        }
      });
    }
  }
};